<template>

  <div class="about-container">

    <div class="banner-container">
      <div class="banner-box">
        <img src="../assets/res/title4.jpg" alt="" class="banner-img">
      </div>
    </div>

    <div class="container">
      <div class="about-box">
        <div class="box-title">
          <div class="title">
            关于我们
          </div>
        </div>
        <div class="content-box">
          <div class="con-pic">
            <img src="../assets/res/about.jpg" alt="">
          </div>
          <div class="content">
            <p>
              北京轩辕方舟科技有限公司成立于2017年11月30日，注册地位于北京市门头沟区莲石湖西路98号院5号楼703室G12（集群注册），法定代表人为邹继先。经营范围包括技术服务、开发、推广、转让、咨询；信息系统集成服务；销售计算机软硬件及外设、机械设备、电子产品；计算机系统服务；经济信息咨询；会议服务；货物进出口；技术进出口；机械设备租赁；技术检测。（市场主体依法自主选择经营项目，开展经营活动；依法须经批准的项目，经相关部门批准后依批准的内容开展经营活动；不得从事国家和本市产业政策禁止和限制类项目的经营活动。）。
            </p>
            <p>
            &nbsp;
            </p>
            <p>
              &nbsp;
            </p>
          </div>
        </div>
      </div>
      <div class="contact-box">
        <div class="box-title">
          <div class="title">
            联系我们
          </div>
        </div>
        <div class="content-box">
          <div class="content">
            <p>
              北京轩辕方舟科技有限公司
            </p>
            <p>
              地址：北京市西城区德胜置业大厦1号楼1901
            </p>
            <p>
              联系人：许先生
            </p>
            <p>
              电话：13910782545
            </p>
            <p>
              邮箱：xujinxuan@xyark.com
            </p>
            <p>
              &nbsp;
            </p>
            <p>
            &nbsp;
            </p>
          </div>
        </div>
      </div>
      <div class="map-box">
        <div class="box-title">
          <div class="title">
            在线地图
          </div>
        </div>
        <div class="content-box">
          <img src="../assets/res/map2.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  components: {

  }
}
</script>

<style scoped lang="scss">
.about-container {
  .banner-container {
    width: 1200px;
    margin: 0 auto;
    .banner-box {
      margin-top: 5px;
      .banner-img {
        width: 1200px;
        height: 256px;
      }
    }
  }
  .container {
    min-height: 600px;
    width: 1200px;
    margin: 0 auto;

    .box-title {
      width: 100%;
      border-bottom: 2px #999 solid;
      .title {
        margin-bottom: -2px;
        width: 80px;
        line-height: 32px;
        color: #333;
        font-size: 14px;
        font-weight: 400;
        text-shadow: none;
        border-bottom: 2px #ac0000 solid;
      }
    }

    .about-box {
      margin-top: 10px;
      width: 100%;

      .content-box {
        display: flex;
        flex-direction: row;
        margin-top: 5px;
        .content {
          margin: 20px 0 0 10px;
          p {
            font-size: 14px;
            line-height: 1.8;
            padding: 0;
            margin: 0;
            white-space: normal;
            word-wrap: break-word;
            word-break: normal;
            overflow: hidden;
            width: 100%;
            text-align: left;
            text-indent: 2em;
          }
        }
        .con-pic {
          margin-top: 20px;
        }
      }
    }

    .contact-box {
      display: flex;
      flex-direction: column;
      margin-top: 5px;
      .content {
        margin: 20px 0 0 10px;
        p {
          font-size: 14px;
          line-height: 1.8;
          padding: 0;
          margin: 0;
          white-space: normal;
          word-wrap: break-word;
          word-break: normal;
          overflow: hidden;
          width: 100%;
          text-align: left;
        }
      }
    }
  }
}
</style>

